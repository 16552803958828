body {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
  border: 0;
  outline-style: none;
}
li {
  list-style: none;
}
.headerBar {
  padding-left: 20px;
  padding-right: 20px;
}
.backgroundDark {
  background-color: black;
  margin: 0;
}
.backgroundLight {
  background-color: white;
  margin: 0;
}
.headerImage {
  width: 100%;
  object-fit: cover;
  height: 80vh;
}
.headerPageImage {
  width: 100%;
  object-fit: cover;
  height: 35vh;
  filter: /*sepia(100%)*/ /*saturate(300%)*/ brightness(60%)
    /*hue-rotate(180deg)*/;
}
.headerPageContainer {
  position: relative;
  text-align: center;
  color: white;
}
.headerImageText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.headerTextStyle {
  color: white;
}
.pageContent {
  margin: 25px;
}
.socialFooterIcons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.socialFooterIcon {
  padding: 0 !important;
  margin: 5px !important;
}
.footerText {
  text-align: center;
}
.headerImageLinks {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  color: white;
}
.headerImageTextStyle {
  color: white !important;
}
.headerImageLinks:hover {
  text-decoration: underline;
}
.contactItemLinks:hover {
  text-decoration: underline;
}
.contactItem {
  padding: 10px;
}
.projectList {
  padding-top: 20px;
}
